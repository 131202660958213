<template>
  <div class="container">
    <div class="welcome-card rcorners3">
      <h1>Welcome to TYM Analyzer</h1>
      <p>Your trusted tool for advanced analytics and insights.</p>
    </div>
  </div>
</template>


<script>
import UserService from "../services/user.service";

export default {
  name: "Home",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>



<style scoped>
/* Container Background */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #007bff, #00d4ff); /* Blue gradient background */
  font-family: 'Arial', sans-serif; /* Modern font */
  color: #fff;
}

/* Welcome Card Styling */
.welcome-card {
  text-align: center;
  padding: 40px 30px;
  background-color: rgba(255, 255, 255, 0.9); /* Transparent white background */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  max-width: 500px;
  width: 90%;
}

.welcome-card h1 {
  font-size: 2rem;
  color: #333; /* Dark text for good contrast */
  margin-bottom: 10px;
}

.welcome-card p {
  font-size: 1.2rem;
  color: #555;
}
</style>

