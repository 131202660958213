import axios from 'axios';
import authHeader from './auth-header';
import { API_URL } from './variables.js'

class ReportService {
//
getrunstop(m){
  return axios.get(API_URL+`/runstopchart1l/${m}`, { headers: authHeader() });
 }
 getallmangermessages(m){
  return axios.get(API_URL+`/managermessages1l/${m}`, { headers: authHeader() });
 }
 //new day report
getdaystoppages(m){
  return axios.get(API_URL+`/daystoppages1l/${m}`, { headers: authHeader() });
 }
 getallmangermessagespermonth(m,y){
  return axios.get(API_URL+`/daystoppagespermonth1l/${m}/${y}`, { headers: authHeader() });
 }
//
getdaybatches(m){
  return axios.get(API_URL+`/daybatches1l/${m}`, { headers: authHeader() });
 }
//
getdayrunstop(m){
  return axios.get(API_URL+`/dayrunstop1l/${m}`, { headers: authHeader() });
 }
//
getdayppd(m){
  return axios.get(API_URL+`/dayppd1l/${m}`, { headers: authHeader() });
 }
 getstoppages(m){
  return axios.get(API_URL+`/stoppageschart1l/${m}`, { headers: authHeader() });
 }
 getpinmonth(){
  return axios.get(API_URL+`/pinmonth1l`, { headers: authHeader() });
 }
//moststoppages
getmoststoppages(m){
  return axios.get(API_URL+`/moststoppages1l/${m}`, { headers: authHeader() });
 }
 //
 getrunstopbyrunid(){
  return axios.get(API_URL+`/runstopbyrunid1l/`, { headers: authHeader() });
 }
//
getatf(product,m){
  return axios.get(API_URL+`/getatf1l/${product}/${m}`, { headers: authHeader() });
 }
 //
// getRunStop Api to get run time and stop time for mode
getRunStop(mode,m){
  return axios.get(API_URL+`/getrunstop1l/${mode}/${m}`, { headers: authHeader() });
 }
 //
getftime(mid,vid){
 return axios.get(API_URL+`/allftime1l/${mid}/${vid}`, { headers: authHeader() });
}

getmtime(mid,vid,m){
 return axios.get(API_URL+`/allmtime1l/${mid}/${vid}/${m}`, { headers: authHeader() });
}
//
getmtimeC(mid,vid){
  return axios.get(API_URL+`/allmtimeC1l/${mid}/${vid}`, { headers: authHeader() });
 }
//
getmaintmode(mid){
  return axios.get(API_URL+`/maintmode1l/${mid}`, { headers: authHeader() });
 }
 setmaintmode(new2){  //vardelay table
  return axios.get(API_URL+`/maintmodeu/${new2.mid}/${new2.val}`, { headers: authHeader() });
 }
 getlockmode(mid){
  return axios.get(API_URL+`/lockmode1l/${mid}`, { headers: authHeader() });
 }
 async sendonoff(data){
  //return axios.post(API_URL +'/cat',data, { headers: authHeader() });  /${mid}/${val}`
 let r=await  axios.get(API_URL+`/onoff/${data.mid}/${data.val}`,{ headers: authHeader() });
 return r;

 }
 getallproducts(){
  return axios.get(API_URL+`/allproducts1l`, { headers: authHeader() });
 }
 //function api to update product from backend
 updateProduct(data){
  return axios.post(API_URL+`/updateproduct1l`,data, { headers: authHeader() });
 }
 //function api to delete product from backend
 delProduct(id){
  return axios.get(API_URL +`/delproduct1l/${id}`, { headers: authHeader() });
}
 //function api to create new product from backend
 createProduct(data){
  return axios.post(API_URL+`/createproduct1l`,data, { headers: authHeader() });
 }
 //

 //get rates from api  1/6/2021
getrates(){
  return axios.get(API_URL+`/rates1l`, { headers: authHeader() });
 }
//  getmonthrates(m){
//   return axios.get(API_URL+`/monthrates1l/${m}`, { headers: authHeader() });
//  }
 getmonthrates(m){
  return axios.get(API_URL+`/monthrates1l/${m}`, { headers: authHeader() });
 }
 getdayrates(m){
  return axios.get(API_URL+`/dayrates1l/${m}`, { headers: authHeader() });
 }
 getlastmode(){
  return axios.get(API_URL+`/lastbatch1l`, { headers: authHeader() });
 }
//
 //////////
 getallinbatch(id){
  return axios.get(API_URL+`/ppdbyrun1l/${id}`, { headers: authHeader() });
 }
 ////
///lastbatch

//to create new batch
letmode(data){
  return axios.post(API_URL+`/letmode1l`,data, { headers: authHeader() });
 }
 batchesupdate(data) {
  return axios.post(API_URL+"/batchesu1l",data, { headers: authHeader() });
}
delbatch(id){
  return axios.get(API_URL +`/delbatch1l/${id}`, { headers: authHeader() });
}
///////////////////////
 timestrToSec(timestr) {
  var parts = timestr.split(":");
  return (parts[0] * 3600) +
         (parts[1] * 60) +
         (+parts[2]);
}

 pad(num) {
  if(num < 10) {
    return "0" + num;
  } else {
    return "" + num;
  }
}

 formatTime(seconds) {
  return [pad(Math.floor(seconds/3600)),
          pad(Math.floor(seconds/60)%60),
          pad(seconds%60),
          ].join(":");
}
//
sec2time(timeInSeconds) {
  var pad = function(num, size) { return ('000' + num).slice(size * -1); },
  time = parseFloat(timeInSeconds).toFixed(3),
  hours = Math.floor(time / 60 / 60),
  minutes = Math.floor(time / 60) % 60,
  seconds = Math.floor(time - minutes * 60),
  milliseconds = time.slice(-3);

  return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);
}


 getDaysInMonth = function(month,year) {
  // Here January is 1 based
  //Day 0 is the last day in the previous month
 return new Date(year, month, 0).getDate();
// Here January is 0 based
// return new Date(year, month+1, 0).getDate();
};


}

export default new ReportService();
