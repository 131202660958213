<template>
  <div id="app">
    <title>Ayman TYM</title>

    <!-- Sliding Sidebar -->
    <div :class="['sidebar', { 'sidebar-collapsed': isSidebarCollapsed }]">
      <div class="sidebar-header text-center py-3">
        <button class="smart-btn toggle-btn" @click="toggleSidebar">
  <font-awesome-icon :icon="isSidebarCollapsed ? 'angle-right' : 'angle-left'" />
</button>

      </div>
      <ul class="nav flex-column">
        <li v-if="showAdminBoard" class="nav-item">
          <!-- <a href="#" class="nav-link">
            <font-awesome-icon icon="chart-bar" />

          </a> -->
          <ul class="submenu" v-if="!isSidebarCollapsed">
            <li><router-link to="/managermessages" class="nav-link">Actual KPIs</router-link></li>
            <li><router-link to="/managermessages-tot" class="nav-link">Monthly KPI</router-link></li>
            <li><router-link to="/stoppagespermonth" class="nav-link">Monthly KPI2</router-link></li>
            <li><router-link to="/stoppages_cat" class="nav-link">Stoppages</router-link></li>
            <li><router-link to="/dayreport" class="nav-link">Daily Report</router-link></li>
          </ul>
        </li>
      </ul>
      <a href="#" class="nav-link">
            <font-awesome-icon icon="cogs" />
            <!-- <span v-if="!isSidebarCollapsed">Configuration</span> -->
          </a>
      <ul class="submenu" v-if="!isSidebarCollapsed">
            <!-- <li><router-link to="/run_stop_chart" class="nav-link">Run/Stop Chart</router-link></li> -->
            <li><router-link to="/stoppages_chart" class="nav-link">Stoppages Chart</router-link></li>
            <li><router-link to="/stoppages_most" class="nav-link">Peak Stoppages</router-link></li>
            <li><router-link to="/p_inmonth" class="nav-link">Production Chart</router-link></li>
            <li><router-link to="/chart3monthly" class="nav-link">Monthly Chart</router-link></li>
          </ul>
          <li v-if="showAdminBoard" class="nav-item">
            <a href="#" class="nav-link">
            <font-awesome-icon icon="cogs" />
            <!-- <span v-if="!isSidebarCollapsed">Configuration</span> -->
          </a>
          <ul class="submenu" v-if="!isSidebarCollapsed">
            <li><router-link to="/messedit" class="nav-link">Normal Edit</router-link></li>
            <li><router-link to="/messedit2" class="nav-link">Anomalies Edit</router-link></li>
            <li><router-link to="/target" class="nav-link">Targets</router-link></li>
            <!-- <li v-if="showAdminBoard " class="nav-item">
              <router-link style="color:black" to="/target" class="nav-link">Targets</router-link>
          </li> -->
            <!-- <li><router-link to="/subcats" class="nav-link">Sub Category</router-link></li>
            <li><router-link to="/failures" class="nav-link">Failure</router-link></li>
            <li><router-link to="/product" class="nav-link">Products</router-link></li> -->
          </ul>
        </li>
        <a href="#" class="nav-link">
            <font-awesome-icon icon="cogs" />
            <!-- <span v-if="!isSidebarCollapsed">Configuration</span> -->
          </a>
      <!-- Logout Button -->
      <div class="sidebar-footer text-center py-3">
        <button class="btn btn-danger" @click="logOut">
          <font-awesome-icon icon="sign-out-alt" />
          <span v-if="!isSidebarCollapsed">Logout</span>
        </button>
      </div>
    </div>

    <!-- Main Content -->
    <div :class="['main-content', { 'content-expanded': isSidebarCollapsed }]">
      <div class="content-container">
        <router-view />
      </div>
    </div>
  </div>
</template>


---

### Script
```vue
<script>
export default {
  data() {
    return {
      isSidebarCollapsed: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      return this.currentUser?.roles?.includes("ROLE_ADMIN");
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>


<style scoped>
/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background: #f8f9fa;
  color: #1d64aa;
}

/* Sidebar Styling */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 220px;
  height: 100vh;
  background: #232527;
  color: #fff;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
}

.sidebar-collapsed {
  transform: translateX(-100%); /* Hidden sidebar */
}

.sidebar-header {
  padding: 10px;
  text-align: center;
  background-color: #212529;
  border-bottom: 1px solid #495057;
}

.sidebar ul {
  list-style: none;
}

.sidebar .nav-link {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  text-decoration: none;
  color: #ccc;
  transition: background 0.3s ease, color 0.3s ease;
}

.sidebar .nav-link:hover {
  background-color: #495057;
  color: #fff;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background-color: #212529;
  border-top: 1px solid #495057;
}

/* Main Content Styling */
.main-content {
  margin-left: 220px;
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
  transition: margin-left 0.3s ease-in-out;
}

.content-expanded {
  margin-left: 0;
}

/* Responsive Adjustments */
@media (max-width: 992px) { /* Tablet View */
  .sidebar {
    width: 220px;
  }
  .main-content {
    margin-left: 220px;
  }
}

@media (max-width: 768px) { /* Mobile View */
  .sidebar {
    width: 200px;
    transform: translateX(-100%);
  }
  .sidebar.sidebar-collapsed {
    transform: translateX(0);
  }
  .main-content {
    margin-left: 0;
  }
}
.smart-btn {
  background: linear-gradient(90deg, #4CAF50, #81C784); /* Smooth gradient */
  border: none; /* Remove default border */
  color: white; /* Text color */
  font-size: 16px; /* Font size */
  padding: 10px 20px; /* Button size */
  border-radius: 50px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: all 0.3s ease; /* Smooth animation for hover and click */
  display: flex; /* Align icon and text */
  align-items: center; /* Vertical center alignment */
  justify-content: center; /* Horizontal center alignment */
}

.smart-btn:hover {
  background: linear-gradient(90deg, #66BB6A, #A5D6A7); /* Lighter gradient on hover */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  transform: scale(1.05); /* Slight enlargement */
}

.smart-btn:active {
  transform: scale(0.95); /* Slight shrink on click */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow */
}
</style>
