import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.module';
import reportService from '../services/report.service';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    machineName:' ANALYZER', //label of machine

    midDi0:1,          ///1:4l    3:1l
    midDo4:5,          ///5:4l    6:1l
    midMaintMode:100,  ///100:4l  102:1l
    midDo0:1,          ///1:4l    3:1l
    vardeleyMuliplier:4, /// 188:4l  45:1l
    vardelayCarton:11,   /// 11:4l   22:1l   ///the two machines is 1
    midLockMode:501 ,    ///501:4l  503:4l   .........got to message to dispaly if loack signal is on
    mtimer:6001,           ///timer after finish   4l:6001----in vardelay  20l:6002----in vardelay
    sbatch:[],//{id:'',runid:'',startdate:'',enddate:'',dayend:'',sapid:'',productcode:'',empno:''},
    midTagetPei:1004,   //target PEI of 4l 1004, 1l:1001
    midTagetRate:1104,  //target rate of 4l 1104, 1l:1101
    unitperCarton:4,    //4 for 4l  , 12 for 1l
    atfproduct:'atf',
    specialmode:5,
    terminal : 2,// 1 for mex , 2 for Asher
    machineNo: 1,
    userService: false,
    reportService: false,
    // machineData: {},
  },
  mutations: {
    // تحديث بيانات الآلة
    // SET_MACHINE_DATA(state, machineData) {
    //   state.machineData = machineData; // تحديث الحالة ببيانات الآلة
    // },

    // تبديل خدمة المستخدم
    SET_USER_SERVICE(state, isUserServiceEnabled) {
      state.userService = isUserServiceEnabled; // تمكين/تعطيل خدمة المستخدم
    },

    // تبديل خدمة التقرير
    SET_REPORT_SERVICE(state, isReportServiceEnabled) {
      state.reportService = isReportServiceEnabled; // تمكين/تعطيل خدمة التقرير
    }

  },
    actions: {
      // async loadStateFromFile({ commit }) {
      //   try {
      //     const response = await fetch('/state.json'); // تأكد من وضع المسار الصحيح
      //     const data = await response.json(); // تحويل النص إلى JSON
      //     commit('setMachineData', data); // إرسال البيانات إلى الحالة
      //   } catch (error) {
      //     console.error('Failed to load state:', error);
      //   }
      // },
    },
  modules: {
    auth
  },
});


// export default new Vuex.Store({
//   modules: {
//     auth
//   }
// });
